import { GenericAPIData } from "core/entities";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useRouter } from "next/router";
import { NewsEventAggregation } from "resources/APIModel";
import { ICategorySummaryDto } from "../entities";

export function useNewsEventsTypes(type: "news" | "events") {
  const { locale } = useRouter();
  const { data, isError, isLoading, mutate } = useAPIAll<
    GenericAPIData<ICategorySummaryDto>
  >({
    path: "/api/news-events/categories/CategoriesSummaries",
    params: {
      aggregation:
        type === "news"
          ? NewsEventAggregation.Newstype
          : NewsEventAggregation.Eventtype,
      locale,
    },
  });

  return { data, isError, isLoading, mutate };
}
