import { useTranslation } from "react-i18next";
import { useFormatDate } from "core/hooks/useFormatDate";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "core/components/Link";
import isSameDate from "date-fns/isSameDay";
import { IEventArticleSummaryDto } from "features/NewsEvents/entities";
import OpenInNew from "@mui/icons-material/OpenInNew";

type BigEventWidgetTileProps = Pick<
  IEventArticleSummaryDto,
  "url" | "title" | "address" | "startDate" | "endDate" | "id"
>;

export function BigEventWidgetTile({
  id,
  startDate,
  endDate,
  title,
  address,
  url,
}: BigEventWidgetTileProps) {
  const { t } = useTranslation("NewsEvents", {
    keyPrefix: "Next:NewsEvents:EventsWidget",
  });
  const getMonth = useFormatDate({ month: "long" });
  const formatDate = useFormatDate();
  const formatTime = useFormatDate({ timeStyle: "short" });
  const isOneDayFirstEvent = isSameDate(new Date(startDate), new Date(endDate));
  const firstEventMonth = getMonth(startDate);
  const firstEventDate = new Date(startDate).getDate();
  const firstEventStartTime = formatTime(startDate);
  const firstEventEndDate = formatDate(endDate);
  const firstEventHref = `/events/${id}`;
  return (
    <Box>
      <Link href={firstEventHref}>
        <Stack
          width="min(300px, 100%)"
          mx="auto"
          borderRadius="5px"
          bgcolor="primary.main"
          color="primary.contrastText"
          textAlign="center"
          alignItems="center"
          py={2}
          mb={1}
        >
          <Typography fontSize="32px">{firstEventMonth}</Typography>
          <Typography fontSize="96px" fontWeight={300}>
            {firstEventDate}
          </Typography>
          <Typography>{firstEventStartTime}</Typography>
          {!isOneDayFirstEvent && (
            <Typography fontSize="16px">to {firstEventEndDate}</Typography>
          )}
        </Stack>
      </Link>
      <Stack mt={2} spacing={1} alignItems="center">
        <Link href={firstEventHref}>
          <Typography variant="h5">{title}</Typography>
        </Link>
        {address && (
          <Link
            target="_blank"
            href={`https://www.google.com/maps/search/${encodeURIComponent(
              address
            )}`}
          >
            <Typography color="text.secondary">
              <LocationOnIcon sx={{ marginBottom: "-3px" }} color="primary" />{" "}
              {address}
            </Typography>
          </Link>
        )}
        {url && (
          <Link
            href={url}
            target="_blank"
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <OpenInNew sx={{ marginBottom: "-3px" }} color="primary" /> {url}
          </Link>
        )}
        <Button href={firstEventHref} LinkComponent={Link}>
          {t("details")}
        </Button>
      </Stack>
    </Box>
  );
}
