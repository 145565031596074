import { useState } from "react";
import { PageHeader } from "core/components/PageHeader";
import { ReactHookFormSelect } from "core/components/ReactHookFormComponents/ReactHookFormSelect";
import { ReactHookTextField } from "core/components/ReactHookFormComponents/ReactHookTextField";
import { ReactHookEmailInput } from "core/components/ReactHookFormComponents/ReactHookEmailInput";
import { SubmitButton } from "core/components/SubmitButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FieldErrorsImpl, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { useToaster } from "core/hooks/useToaster";
import { useValidationErrorToaster } from "core/hooks/useValidationErrorToaster";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useRouter } from "next/router";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Cancel from "@mui/icons-material/Cancel";
import { FavoriteButton } from "features/MyLinks/components";
import { PageContainer } from "core/components/PageContainer";

export interface ITrainingRequestCreateDto {
  action: string;
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  language: string;
  job: string;
  manager: string;
}

const defaultValues: ITrainingRequestCreateDto = {
  action: "",
  firstName: "",
  lastName: "",
  email: "",
  location: "",
  language: "",
  job: "",
  manager: "",
};

export function TrainingRequestPage() {
  const { locales } = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { back } = useRouter();
  const post = useAPIPostJSON();
  const { successToast, errorToast } = useToaster();
  const validationErrorToaster = useValidationErrorToaster();
  const formMethods = useForm({ defaultValues });
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:TrainingRequest",
  });
  const { t: tBase } = useTranslation();

  async function onValidSubmit(form: ITrainingRequestCreateDto) {
    setIsSubmitting(true);
    try {
      await post("/api/app/training-requests", form);
      successToast(t("submitSuccess"));
      formMethods.reset(defaultValues);
    } catch {
      errorToast(t("submitError"));
    }
    setIsSubmitting(false);
  }

  function onInvalidSubmit(errors: FieldErrorsImpl<ITrainingRequestCreateDto>) {
    validationErrorToaster(Object.keys(errors).map((name) => t(name)));
  }
  const actionOptions: { label: string; value: string }[] = [
    { label: t("action.add"), value: t("action.add") },
    { label: t("action.remove"), value: t("action.remove") },
    { label: t("action.update"), value: t("action.update") },
  ];
  const localeOptions =
    locales
      ?.filter((locale) => locale !== "default")
      .map((locale) => {
        const value = tBase(`Next:Core:LanguageCodes.${locale}`);
        return { label: value, value };
      }) ?? [];
  return (
    <PageContainer maxWidth="md">
      <PageHeader
        title={t("pageTitle")}
        subTitle={t("subTitle")}
        userGuideHref="https://app.tango.us/app/workflow/Submitting-a-Training-Request-3ea7dac160a74fe2a6913131a8bb89e5"
        TitleSlot={
          <FavoriteButton
            itemId="training-request"
            itemType="Page"
            title={t("pageTitle")}
          />
        }
        RightSlot={
          <Button startIcon={<ChevronLeft />} variant="outlined" onClick={back}>
            {t("goBack")}
          </Button>
        }
      />
      <FormProvider {...formMethods}>
        <Stack
          gap={2}
          component="form"
          onSubmit={formMethods.handleSubmit(onValidSubmit, onInvalidSubmit)}
        >
          <ReactHookFormSelect
            required
            name="action"
            label={t("action")}
            options={actionOptions}
          />
          <ReactHookTextField
            name="firstName"
            label={t("firstName")}
            required
          />
          <ReactHookTextField name="lastName" label={t("lastName")} required />
          <ReactHookEmailInput
            name="email"
            required
            TextFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t("emailTooltipTitle")}>
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
            label={t("email")}
          />
          <ReactHookTextField required name="location" label={t("location")} />
          <ReactHookFormSelect
            required
            name="language"
            label={t("language")}
            options={localeOptions}
          />
          <ReactHookTextField name="job" label={t("job")} required />
          <ReactHookTextField required name="manager" label={t("manager")} />

          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Button
              onClick={back}
              startIcon={<Cancel />}
              variant="outlined"
              color="primary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => formMethods.reset(defaultValues)}
              startIcon={<RestartAltIcon />}
              variant="outlined"
              color="primary"
            >
              {t("reset")}
            </Button>

            <SubmitButton loading={isSubmitting} />
          </Stack>
        </Stack>
      </FormProvider>
    </PageContainer>
  );
}
