import { ReactNode } from "react";
import Box from "@mui/material/Box";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { StyledListItem } from "core/components/StyledListItem";
import { useBreakpoints } from "core/hooks/useBreakpoints";

interface SearchItemTemplateProps {
  title: string;
  blurb: string;
  dateLabel?: string;
  isFavorited: boolean;
  dateText?: string;
  AvatarIcon: ReactNode;
  secondaryAction: ReactNode;
  "data-testid": string;
}

export function SearchItemTemplate({
  title,
  blurb,
  dateLabel,
  dateText,
  AvatarIcon,
  secondaryAction,
  isFavorited,
  "data-testid": testId,
}: SearchItemTemplateProps) {
  const { isMobile } = useBreakpoints();
  if (isMobile)
    return (
      <StyledListItem
        data-testid={testId}
        data-component="search-item"
        sx={{ gap: 2, paddingRight: 10 }}
        secondaryAction={<Box color="primary.main">{secondaryAction}</Box>}
      >
        <ListItemAvatar sx={{ minWidth: "auto" }}>
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            }}
          >
            {AvatarIcon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            flex: 1,
            "& .MuiListItemText-secondary": {
              maxWidth: "40ch",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            },
          }}
          primary={title}
          secondary={
            <>
              {dateText}
              {dateText && <br />}
              {blurb}
            </>
          }
        />

        {isFavorited ? (
          <StarIcon color="primary" />
        ) : (
          <StarBorderIcon color="primary" />
        )}
      </StyledListItem>
    );
  return (
    <StyledListItem
      data-testid={testId}
      data-component="search-item"
      sx={{ gap: 2, paddingRight: 10 }}
      secondaryAction={secondaryAction}
    >
      <ListItemAvatar sx={{ minWidth: "auto" }}>
        <Avatar
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {AvatarIcon}
        </Avatar>
      </ListItemAvatar>
      <Box
        flex={1}
        gap={2}
        alignItems="center"
        display="grid"
        gridTemplateColumns="1fr 1fr auto"
      >
        <ListItemText
          sx={{
            flex: 1,
            "& .MuiListItemText-secondary": {
              maxWidth: "40ch",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            },
          }}
          primary={title}
          secondary={blurb}
        />
        {dateText && dateLabel ? (
          <ListItemText
            sx={{ flex: 1 }}
            primary={dateLabel}
            primaryTypographyProps={{
              color: "text.secondary",
              variant: "body2",
            }}
            secondary={dateText}
            secondaryTypographyProps={{
              color: "text.primary",
              variant: "body1",
            }}
          />
        ) : (
          <div />
        )}

        {isFavorited ? <StarIcon sx={{ color: "grey.600" }} /> : <div />}
      </Box>
    </StyledListItem>
  );
}
