import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { conditionCodeIconMap } from "./WeatherWidget/ConditionCodeIconMap";

interface WeatherIconProps {
  code: string;
  fontSize?: string;
}

export function WeatherIcon({ code, fontSize = "2rem" }: WeatherIconProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:WeatherIcon",
  });
  const conditionCodeEntry = conditionCodeIconMap[code];
  if (!conditionCodeEntry) return null;
  const { IconComponent } = conditionCodeEntry;
  const codeText = t(conditionCodeEntry.translationKeySuffix);
  return (
    <Tooltip title={codeText}>
      <Box>
        <IconComponent sx={{ fontSize }} />
      </Box>
    </Tooltip>
  );
}
