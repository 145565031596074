import { useAPIInfinite } from "core/hooks/useAPIInfinite";
import {
  EventArticleFiltersProperties,
  IEventArticleSummaryDto,
} from "../entities";

export function useEventArticleList(
  locale: string,
  filters: EventArticleFiltersProperties,
  maxResultCount: number = 10
) {
  const APIInfiniteReturn = useAPIInfinite<IEventArticleSummaryDto>({
    path: "/api/news-events/articles/Events/public",
    params: { locale, ...filters },
    maxResultCount,
  });

  return APIInfiniteReturn;
}
