import { useMemo } from "react";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";
import { APIError, GenericAPIData, Params, ReqPath } from "core/entities";
import { BareFetcher } from "swr";
import { useAPIGetJson } from "./useAPIGetJson";

export function useAPIInfinite<T = any>(
  {
    path,
    params,
    shouldFetch = true,
    maxResultCount = 20,
  }: {
    path: string;
    params?: Params;
    shouldFetch?: boolean;
    maxResultCount?: number;
  },
  config?: SWRInfiniteConfiguration<
    GenericAPIData<T>,
    APIError,
    BareFetcher<GenericAPIData<T>>
  >
) {
  const fetcher = useAPIGetJson();
  const getKey: (
    index: number,
    previousPageData: GenericAPIData<T>
  ) => null | ReqPath = (index, previousPageData) => {
    if (!shouldFetch) return null;
    if (
      Array.isArray(previousPageData?.items) &&
      previousPageData.items.length < maxResultCount
    ) {
      return null;
    }
    const key = {
      path,
      params: { maxResultCount, skipCount: index * maxResultCount, ...params },
    };
    return key;
  };

  const { isValidating, mutate, setSize, size, data, error } = useSWRInfinite<
    GenericAPIData<T>,
    APIError
  >(getKey, fetcher, config);

  const isLoadingInitialData = !error && !data;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  const processedData = useMemo(() => {
    if (error) {
      return {
        items: [] as T[],
        totalCount: 0,
        currentCount: 0,
        showLoadMore: false,
      };
    }
    const items = Array.isArray(data)
      ? data?.flatMap((entry) => entry.items)
      : [];
    const currentCount = items.length;
    const totalCount = data?.[0]?.totalCount || 0;
    const showLoadMore = currentCount < totalCount;

    function loadMore() {
      if (currentCount >= totalCount || isLoadingMore || isLoadingInitialData)
        return;
      setSize((currentSize) => currentSize + 1);
    }

    return {
      items,
      currentCount,
      totalCount,
      showLoadMore,
      loadMore,
    };
  }, [data, error, isLoadingMore, isLoadingInitialData, setSize]);

  return {
    isLoadingInitialData,
    isLoadingMore,
    isError: error,
    isValidating,
    mutate,
    setSize,
    size,
    data,
    error,
    ...processedData,
  };
}
