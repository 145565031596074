import { GenericAPIData } from "core/entities";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useRouter } from "next/router";
import { NewsEventAggregation } from "resources/APIModel";
import { ICategorySummaryDto } from "../entities";

export function useNewsEventCategories(isFeatured?: boolean | undefined) {
  const { locale } = useRouter();
  const { data, isError, isLoading, mutate } = useAPIAll<
    GenericAPIData<ICategorySummaryDto>
  >({
    path: "/api/news-events/categories/CategoriesSummaries",
    params: { aggregation: NewsEventAggregation.Category, locale, isFeatured },
  });

  return { data, isError, isLoading, mutate };
}
