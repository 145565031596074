import { INewsArticleSummaryDto } from "features/NewsEvents/entities";
import { useRouter } from "next/router";
import { getBrandCode } from "core/utils/getBrandCode";
import { useAPI } from "core/hooks/useAPI";
import { useRegion } from "core/hooks/useRegion";
import { GenericAPIData } from "core/entities";

export function useNewsWidgetArticles(
  category: string | null,
  maxResultCount: number = 4
) {
  const { locale } = useRouter();
  const brand = getBrandCode();
  const region = useRegion();
  const brands = brand ? [brand] : [];
  const regions = region ? [region] : [];
  const path = "/api/news-events/articles/News/public";
  const baseParams = {
    brands,
    regions,
    categories: category!,
    locale,
    sorting: "publishedTime desc",
    maxResultCount,
  };
  const featuredKey = {
    path,
    params: {
      ...baseParams,
      isFeatured: true,
    },
  };
  const notFeaturedKey = {
    path,
    params: {
      ...baseParams,
      isFeatured: false,
    },
  };
  const {
    data: featuredData,
    isError: isFeaturedError,
    isLoading: isFeaturedLoading,
  } = useAPI<GenericAPIData<INewsArticleSummaryDto>>(
    category ? featuredKey : null
  );
  const {
    data: notFeaturedData,
    isError: notFeaturedError,
    isLoading: notFeaturedLoading,
  } = useAPI<GenericAPIData<INewsArticleSummaryDto>>(
    category ? notFeaturedKey : null
  );
  const items =
    featuredData &&
    notFeaturedData &&
    Array.isArray(featuredData?.items) &&
    Array.isArray(notFeaturedData?.items)
      ? [...featuredData.items, ...notFeaturedData.items].slice(
          0,
          maxResultCount
        )
      : [];
  return {
    items,
    isError: isFeaturedError || notFeaturedError,
    isLoading: isFeaturedLoading || notFeaturedLoading,
  };
}
