import { GenericAPIData } from "core/entities";
import { useAPI } from "core/hooks/useAPI";
import { IMultipleWeatherForecastDto } from "../entities";

export function useWeatherForecast(days: number = 7) {
  const { data, isError, isLoading, mutate } = useAPI<
    GenericAPIData<IMultipleWeatherForecastDto>
  >({
    path: "weather/forecast-current-user-locations",
    params: { days },
  });

  return { data, isError, isLoading, mutate };
}
